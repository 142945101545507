import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const SitemapContainer = styled(Container)`
  h4 {
    font-weight: 700;
  }

  a {
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Sitemap = (props) => {
  const ChildPageData = useStaticQuery(graphql`
    query {
      allContentfulResource(
        sort: { order: ASC, fields: resourceTitle }
        filter: { hideFromLibrary: { eq: false } }
      ) {
        edges {
          node {
            resourceTitle
            url
          }
        }
      }
      allContentfulCustomerStory(sort: { fields: priority, order: ASC }) {
        edges {
          node {
            name
            url
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Site map | PayHero"
        description="Site map - all PayHero website links"
        pathname={props.location.pathname}
      />
      <SitemapContainer>
        <Wrapper maxWidth={1200}>
          <Row stackGap={70}>
            <Box size={33} stackGap={50}>
              <Box stackGap={15}>
                <h4>Features</h4>
                <hr />
                <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>Payroll</Link>
                <Link to="/rostering">Rostering</Link>
                <Link to={process.env.GATSBY_SITE_URL + '/time'}>Online Timesheets</Link>
                <Link to={process.env.GATSBY_SITE_URL + '/time'}>Photo Time Clock</Link>
                <Link to={process.env.GATSBY_SITE_URL + '/employee-self-service'}>Mobile App</Link>
                <Link to={process.env.GATSBY_SITE_URL + '/employee-self-service'}>Employee Self Service</Link>
                <Link to={process.env.GATSBY_SITE_URL + '/'}>Leave Management</Link>
                <Link to="/payday-filing">Payday Filing</Link>
                <Link to={process.env.GATSBY_SITE_URL + '/compliance'}>
                  Holidays Act Compliance
                </Link>
              </Box>
              <Box stackGap={15}>
                <h4>Customers</h4>
                <hr />
                <Link to="/case-studies">All Customer Stories</Link>
                {ChildPageData.allContentfulCustomerStory.edges.map(
                  (edge, i) => {
                    return (
                      <Link to={`/case-studies/` + edge.node.url} key={i}>
                        {edge.node.name}
                      </Link>
                    );
                  }
                )}
              </Box>
              <Box stackGap={15}>
                <h4>Payroll Conversions</h4>
                <hr />
                <Link to="/ace-payroll">Ace</Link>
                <Link to="/ims-payroll">IMS</Link>
                <Link to="/myob-payroll">MYOB</Link>
                <Link to="/smartly-payroll">Smartly</Link>
              </Box>
            </Box>
            <Box size={33} stackGap={50}>
              <Box stackGap={15}>
                <h4>Industries</h4>
                <hr />
                <Link to="/ca">Accountants</Link>
                <Link to="/icnzb">Bookkeepers</Link>
                <Link to="/farmers-payroll">Farmers</Link>
                <Link to="/franchise-payroll">Franchises</Link>
                <Link to="/horticulture">Horticulture</Link>
                <Link to="/hospitality">Hospitality</Link>
                <Link to="/manufacturing">Manufacturing</Link>
                <Link to="/recruitment">Recruitment</Link>
                <Link to="/retail">Retail</Link>
                <Link to="/small-business-payroll">Small Business</Link>
                <Link to="/trades-payroll">Trades</Link>
              </Box>
              <Box stackGap={15}>
                <h4>Company</h4>
                <hr />
                <Link to="/blog">Resource Library</Link>
                <Link to="/case-studies">Customer Stories</Link>
                <Link to="/contact">Contact Us</Link>
                <a
                  target="_blank"
                  href="https://support.payhero.co.nz/hc/en-us"
                >
                  Support
                </a>
                <Link to="/payroll-glossary">Payroll Glossary</Link>
                <Link to="/tutorials">Tutorial Videos</Link>
                {/* <Link to="/events">Online Events</Link> */}
              </Box>
              <Box stackGap={15}>
                <h4>Integrations</h4>
                <hr />
                <Link to="/add-ons">All Integrations</Link>
                <Link to="/rostering">Droppah</Link>
                <Link to="/karmly">Karmly</Link>
                <Link to="/xero">Xero</Link>
                <Link to="/akahu">Akahu</Link>
                <Link to="/payday-filing">Inland Revenue</Link>
                <Link to="/myhr">MyHR</Link>
              </Box>
            </Box>
            <Box size={33} stackGap={50}>
              <Box stackGap={15}>
                <h4>Resources</h4>
                <hr />
                <Link to="/blog">All Resources</Link>
                {ChildPageData.allContentfulResource.edges.map((edge, i) => {
                  return (
                    <Link to={`/blog/` + edge.node.url} key={i}>
                      {edge.node.resourceTitle}
                    </Link>
                  );
                })}
              </Box>
            </Box>
          </Row>
        </Wrapper>
      </SitemapContainer>
    </Layout>
  );
};

export default Sitemap;
